import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map, Marker, Popup, TileLayer, LayersControl, GeoJSON } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-active-area';
import { pointInLayer } from '@mapbox/leaflet-pip';

const NZ_BOUNDS = [[-47.4, 166.5], [-34.4, 178.5]];

let _self;

export default class SpaceLayer extends GeoJSON {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    const map = this.context.map;
    const layerBounds = this.leafletElement.getBounds();
    map.fitBounds(layerBounds);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  componentDidUpdate() {
    if (this.props.data.features) {
      this.leafletElement.clearLayers().addData(this.props.data);
      console.log('Updated', this.props);
    }
  }
  static get defaultProps() {
    return {
      onEachFeature: function(feature, layer) {
        layer.on({
          click: function(evt) {
            try {
              var intersected_locality = pointInLayer(evt.latlng, _self.leafletElement, true);
            } catch (error) {
              console.error(error);
            }

            if (evt.target.feature) {
              this._map.fitBounds(this.getBounds());
              // _self.selectConsent(evt.target.feature.id);
              L.DomEvent.stopPropagation(evt);
            } else if (intersected_locality.length === 1) {
              this._map.fitBounds(this.getBounds());
              // _self.selectConsent(intersected_locality[0].feature.id);
              L.DomEvent.stopPropagation(evt);
            }
          },
        });
      },
    };
  }
}
