import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';
import './App.css';
import { view, store } from 'react-easy-state';
import { appStore } from './store';
import Router from './components/Router';
import './App.css';
class App extends Component {
  render() {
    return <Router />;
  }
}

export default view(App);
