import React, { Component } from 'react';
// import moment from 'moment';
import * as moment from 'moment-timezone';
class Loader extends Component {
  render() {
    var times = [
      ['NYSE', 'New York Stock Exchange', '09:30', '16:00', 'America/New_York'],
      ['TSE', 'Tokyo Stock Exchange', '09:00', '15:00', 'Asia/Tokyo'],
      ['LSE', 'London Stock Exchange', '08:00', '16:30', 'Europe/London'],
      ['HKE', 'Hong Kong Stock Exchange', '09:30', '16:00', 'Asia/Hong_Kong'],
      ['NSE', 'National Stock Exchange of India', '09:00', '15:30', 'Asia/Karachi'],
      ['BM&F', 'Bovespa  Bolsa de Valores, Mercadorias & Futuros de Sao Paulo', '10:00', '17:00', 'America/Brasilia'],
      ['ASX', 'Australian Securities Exchange', '10:00', '16:00', 'Australia/Sydney'],
      ['FWB', 'Frankfurt Stock Exchange - Deutsche Borse', '09:00', '20:00', 'Europe/Berlin'],
      ['RTS', 'Russian Trading System', '09:30', '19:00', 'Europe/Moscow'],
      ['JSE', 'Johannesburg Stock Exchange', '09:00', '17:00', 'Africa/Johannesburg'],
      // ['DIFX', 'Dubai International Financial Exchange now NASDAQ Dubai', '10:00', '14:00', 'Abu Dhabi'],
      ['SSE', 'Shanghai Stock Exchange', '09:15', '15:00', 'Asia/Shanghai'],
      ['NZSX', 'New Zealand Stock Exchange', '10:00', '17:00', 'Pacific/Auckland'],
      ['TSX', 'Toronto Stock Exchange', '09:30', '16:00', 'America/New_York'],
    ];
    var timeFormat = 'YYYY-MM-DD hh:mm';
    var dateFormat = 'YYYY-MM-DD';
    var timeZone = 'Pacific/Auckland';
    var now = moment().format(timeFormat);
    times.forEach(t => {
      var date = moment().tz(t[4]);
      var weekend = date.day() == 0 || date.day() == 7 || date.day() == 6;
      var open = moment
        .tz(`${date.format(dateFormat)} ${t[2]}`, t[4])
        .clone()
        .tz(timeZone);

      var close = moment
        .tz(`${date.format(dateFormat)} ${t[3]}`, t[4])
        .clone()
        .tz(timeZone);

      t[5] = open;
      t[6] = close;
      t[7] = !weekend && moment().isAfter(open) && moment().isBefore(close);
    });

    return (
      <div style={{ width: '1514' }}>
        <h3>{}</h3>
        <table class="table">
          <tr>
            <th>Exchange</th>
            <th>Exchange (name)</th>
            <th>Open</th>
            <th>Close</th>
            <th>Open (NZT)</th>
            <th>Close (NZT)</th>
            <th>Time Till Close/Open</th>
            <th>Is Open</th>
          </tr>

          {times
            .sort(function(a, b) {
              return a[5].valueOf() - b[5].valueOf();
            })
            .map(time => (
              <tr>
                <td>{time[0]}</td>
                <td>{time[1]}</td>
                <td>{time[2]}</td>
                <td>{time[3]}</td>
                <td>{time[5].format('hh:mm DD/MM')}</td>
                <td>{time[6].format('hh:mm DD/MM')}</td>
                <td style={{ color: time[7] ? 'green' : 'red' }}>
                  {time[7] ? `Open, closes in ${moment().to(time[6])}` : `Closed, open ${moment().to(time[5])}`}
                </td>
              </tr>
            ))}
        </table>
      </div>
    );
  }
}

export default Loader;
