import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Map, TileLayer, LayersControl, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-active-area';
import { pointInLayer } from '@mapbox/leaflet-pip';
import SpaceLayer from './SpaceLayer';
import L from 'leaflet';
import LocateControl from 'react-leaflet-locate-control';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const NZ_BOUNDS = [[-47.4, 166.5], [-34.4, 178.5]];

let _self;

export default class Webmap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.resetMap = this.resetMap.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  resetMap() {
    this.refs.map.leafletElement.fitBounds(NZ_BOUNDS);
  }

  handleClick(e) {}

  render() {
    const locateOptions = {
      position: 'topright',
      strings: {
        title: 'Show me where I am, yo!',
      },
      flyTo: true,
      onActivate: () => {}, // callback before engine starts retrieving locations
    };
    return (
      <section>
        <Map
          style={{ height: '100vh' }}
          id="map"
          minZoom={2}
          bounds={NZ_BOUNDS}
          zoomSnap={0.1}
          ref="map"
          onClick={this.handleClick}
        >
          {' '}
          <LocateControl options={locateOptions} startDirectly />
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="NZ Aerial" checked>
              <TileLayer
                url="http://tiles-a.data-cdn.linz.govt.nz/services;key=21fcca84a09d4e089dbd87fd625e6091/tiles/v4/set=4702/EPSG:3857/{z}/{x}/{y}.png"
                maxZoom={20}
                attribution=""
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {this.props.data && <SpaceLayer data={this.props.data} />}
        </Map>
      </section>
    );
  }
}
