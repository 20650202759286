import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Webmap from './Webmap';
import Stocks from '../pages/Stocks';
import FourOhFour from '../pages/FourOhFour';

export const UNLISTED_ROUTES = [{ url: '/stocks', name: '', component: Stocks }];

export const ROUTES = [];
export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Webmap} />
          {UNLISTED_ROUTES.map(r => (
            <Route key={r.url} path={r.url} exact component={r.component} />
          ))}
          {ROUTES.map(r => (
            <Route key={r.url} path={r.url} exact component={r.component} />
          ))}
          <Route component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    );
  }
}
